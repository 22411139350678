import Vue from 'vue'
import '@mdi/font/css/materialdesignicons.css'
import App from './App.vue'
import router from './router'
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import agregarFiltros from "./filters"

import "font-awesome/css/font-awesome.css";
import { library } from '@fortawesome/fontawesome-svg-core';
// internal icons
import {  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add();
Vue.component('vue-fontawesome', FontAwesomeIcon);


agregarFiltros(Vue);

Vue.config.productionTip = false
Vue.use(Buefy, {
  defaultIconPack: 'fa'
});
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

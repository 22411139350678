<template>
  <div class="box">
    Resultados: {{ DataAgencias.length }}

    <b-field>
      <b-collapse
        class="card"
        animation="slide"
        aria-id="contentIdForA11y3"
        :open="false"
      >
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
            :aria-expanded="props.open"
          >
            <p class="card-header-title">Agregar Agencia</p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'caret-down' : 'caret-up'"> </b-icon>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="content">
            <b-field :label="NameYDk">
              <b-autocomplete
                rounded
                v-model="agencia"
                :data="filtrarAgencias"
                icon="search"
                field="NUM_CLIENTE"
                placeholder="Clave de Agencia"
                @select="(option) => AgenciaSelec(option)"
              >
                <template slot-scope="props">
                  <div class="media">
                    <div class="media-content">
                      {{
                        props.option.NUM_CLIENTE + " - " + props.option.NOMBRE
                      }}
                      <br />
                    </div>
                  </div>
                </template>
              </b-autocomplete>
            </b-field>
            <b-button
              v-show="agencia.length > 0"
              icon-left="times"
              @click="Limpiar()"
              type="is-danger"
              size="is-small"
              rounded
            />
            <b-button
              v-show="agencia.length > 0"
              icon-left="floppy-o"
              @click="Save()"
              type="is-info"
              size="is-small"
              rounded
            />
          </div>
        </div> </b-collapse
    ></b-field>

    <b-table
      style="font-size: 12px"
      :data="DataAgencias"
      paginated
      :per-page="15"
      bordered
      striped
      narrowed
      hoverable
      focusable
      mobile-cards
    >
      <template>
        <b-table-column
          field="dk"
          label="ID IRIS"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.dk }}
        </b-table-column>
        <b-table-column
          field="nombre"
          label="USER NAME"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.nombre }}
        </b-table-column>

        <b-table-column
          field="eliminar"
          label="Eliminar"
          v-slot="props"
          centerd
        >
          <a @click="eliminar(props.row.id)">
            <b-icon icon="trash" type="is-danger" size="is-small"></b-icon>
          </a>
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
import MainServices from "@/services/MainServices";
export default {
  data() {
    return {
      DataAgencias: [],
      agencias: [],
      agencia: "",
      NameYDk: "",
      nameAgencia: "",
      dk: "",
    };
  },
  computed: {
    filtrarAgencias() {
      return this.agencias.filter((option) => {
        return (
          option.NOMBRE.toString()
            .toLowerCase()
            .indexOf(this.agencia.toLowerCase()) >= 0 ||
          option.NUM_CLIENTE.toString()
            .toLowerCase()
            .indexOf(this.agencia.toLowerCase()) >= 0
        );
      });
    },
  },
  mounted() {
    this.obtenerAgencias();
  },
  methods: {
    Limpiar() {
      this.agencia = "";
      this.NameYDk = "";
    },
    async eliminar(id) {
      const respuesta = await MainServices.Eliminar(id);

      if (respuesta) {
        this.message("Se elimino correctamente ", "is-success");
        this.obtenerAgencias();
      } else {
        this.message("Error al eliminar", "is-danger");
      }
    },
    async obtenerAgencias() {
      this.isLoading = true;
      this.DataAgencias = await MainServices.obtenerAgencias();
      this.agencias = await MainServices.obtenerDKAgencias();
      this.isLoading = false;
    },
    AgenciaSelec(option) {
      if (option == null) {
        this.agencia = "";
      } else {
        this.nameAgencia = option.NOMBRE;
        this.dk = option.NUM_CLIENTE;
        this.NameYDk = this.dk + " - " + this.nameAgencia;
      }
    },
    async Save() {
      const respuesta = await MainServices.NuevaException(
        this.nameAgencia,
        this.dk
      );
      if (respuesta) {
        this.message("Se ha agregado correctamente ", "is-success");
        this.obtenerAgencias();
      } else {
        this.message("Error al actualizar ", "is-danger");
      }
    },
    message(message, tipo) {
      this.$buefy.toast.open({
        message: message,
        type: tipo,
      });
    },
  },
};
</script>


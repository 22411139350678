<template>
  <div class="box">
    Resultados: {{ data.length }}
    <div class="box">
      <b-field>
        <b-input
          placeholder="Introduce la clave"
          v-model="clave"
          required
          ref="fieldclave"
        ></b-input>
        <b-input
          placeholder="Introduce el usuario"
          v-model="usuario"
          required
          ref="fieldusuario"
        ></b-input>
        <b-tooltip label="Agregar">
          <b-button @click="Save()" type="is-info" icon-right="plus" />
        </b-tooltip>
      </b-field>
    </div>
    <b-table
      style="font-size: 12px"
      :data="data"
      paginated
      :per-page="15"
      bordered
      striped
      narrowed
      hoverable
      focusable
      mobile-cards
    >
      <template>
        <b-table-column
          field="clave"
          label="Clave"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.clave }}
        </b-table-column>
        <b-table-column
          field="username"
          label="Usuario"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.username }}
        </b-table-column>

        <b-table-column
          field="eliminar"
          label="Eliminar"
          v-slot="props"
          centerd
        >
          <a @click="eliminar(props.row.id)">
            <b-icon icon="trash" type="is-danger" size="is-small"></b-icon>
          </a>
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
import MainServices from "@/services/MainServices";
export default {
  data() {
    return {
      data: [],
      usuario: undefined,
      clave: undefined,
    };
  },
  mounted() {
    this.obtenerAgentes();
  },
  methods: {
    async obtenerAgentes() {
      this.isLoading = true;
      this.data = await MainServices.obtenerAgentes();
      this.isLoading = false;
    },
    async eliminar(id) {
      const respuesta = await MainServices.EliminarAgente(id);
      if (respuesta) {
        this.message("Se elimino correctamente ", "is-success");
        this.obtenerAgentes();
      } else {
        this.message("Error al eliminar", "is-danger");
      }
    },
    async Save() {
      const fields = ["fieldclave", "fieldusuario"];
      for (const field of fields) {
        if (!this.$refs[field].checkHtml5Validity()) {
          return;
        }
      }

      const respuesta = await MainServices.NuevoAgente(
        this.clave,
        this.usuario
      );
      if (respuesta) {
        this.message("Se ha agregado correctamente ", "is-success");
        this.clave = null;
        this.usuario = null;
        this.obtenerAgentes();
      } else {
        this.message("Error al actualizar ", "is-danger");
      }
    },
    message(message, tipo) {
      this.$buefy.toast.open({
        message: message,
        type: tipo,
      });
    },
  },
};
</script>


<template>
  <div class="box">
    <b-loading :is-full-page="true" v-model="isLoading"> </b-loading>
    <div class="box" v-if="NewTable == false" style="text-align: center">
      <br />
      &nbsp; &nbsp; &nbsp;
      <br />

      <div class="upload">
        <b-field class="file is-info" :class="{ 'has-name': !!file }">
          <b-upload
            v-model="file"
            class="file-label"
            @input="onChange"
            accept=".xls,.xlsx"
          >
            <span class="file-cta">
              <b-icon class="file-icon" icon="upload"></b-icon>
              <span class="file-label">Cargar Archivo</span>
            </span>

            <span class="file-name" v-if="file">
              {{ file.name }}
            </span>
          </b-upload>
        </b-field>
      </div>

      <b-button icon-left="download" type="is-success" @click="CrearReporteG()"
        >Descargar</b-button
      >

      <br />
      &nbsp; &nbsp; &nbsp;
      <br />
      <xlsx-read :file="file">
        <div class="columns">
          <div class="column">
            <xlsx-sheets v-if="file">
              <template #default="{ sheets }">
                <div v-if="sheets.length >= 1">
                  <br />

                  <h1>Elije la hoja del libro</h1>
                  <b-select
                    v-model="selectedSheet"
                    placeholder="Selecciona la opción"
                    rounded
                  >
                    <option v-for="sheet in sheets" :key="sheet" :value="sheet">
                      {{ sheet }}
                    </option>
                  </b-select>
                </div>
              </template>
            </xlsx-sheets>
          </div>

          <div class="column">
            <xlsx-json :sheet="selectedSheet" v-if="selectedSheet">
              <template #default="{ collection }">
                Resultados: <strong>{{ collection.length }}</strong>
                <b-table
                  style="font-size: 12px"
                  :data="collection"
                  paginated
                  :per-page="10"
                  bordered
                  striped
                  narrowed
                  hoverable
                  focusable
                  mobile-cards
                >
                  <template>
                    <b-table-column label="PASAJERO" sortable v-slot="props">
                      {{ props.row.Pasajero }}
                    </b-table-column>
                    <b-table-column label="DOCS" sortable v-slot="props">
                      {{ props.row.DOCS }}
                    </b-table-column>
                    <b-table-column label="LINEA" sortable v-slot="props">
                      {{ props.row.Linea }}
                    </b-table-column>
                  </template>
                </b-table>

                <section v-if="collection && file">
                  <br />
                  <b-button
                    label="Consultar"
                    @click="Resolver(collection)"
                    type="is-info"
                    rounded
                  />
                </section>
              </template>
            </xlsx-json>
          </div>
        </div>
      </xlsx-read>
    </div>

    <div class="box" v-if="this.Groups.length > 0">
      Resultados: <strong>{{ Groups.length }}</strong>
      <b-table :data="Groups" detailed>
        <b-table-column
          field="IdGroups"
          label="IdGroups"
          sortable
          v-slot="props"
        >
          {{ props.row[1].IdGroups }}
        </b-table-column>
        <b-table-column field="Linea" label="Linea" sortable v-slot="props">
          {{ props.row[0][0].Linea }}
        </b-table-column>
        <b-table-column
          field="Pasajeros"
          label="Pasajeros"
          sortable
          v-slot="props"
        >
          {{ props.row[0].length }}
        </b-table-column>
        <b-table-column v-slot="props" field="fieldfchin" label="Fecha Emisión">
          <h1 v-if="props.row[0][0].fechaEmi != null">
            {{ props.row[0][0].fechaEmi }}
          </h1>
          <b-datepicker
            v-if="props.row[0][0].fechaEmi == null"
            rounded
            ref="fieldfchin"
            :show-week-number="true"
            placeholder="Click to select..."
            icon="calendar"
            :icon-right="fchin ? 'close-circle' : ''"
            icon-right-clickable
            @icon-right-click="clearDate"
            required
            v-model="fchin"
            @input="(option) => CapturarFecha(option, props.row[0], 4)"
          >
          </b-datepicker>
        </b-table-column>
        <b-table-column label="Clave aereolinea" sortable v-slot="props">
          <b-input
            v-model="props.row[0][0].cveALinea"
            ref="fieldcveALinea"
            placeholder="Clave aereolinea"
            @input="TextoGroup(props.row[0], 2, props.row[0][0].cveALinea)"
            :value="props.row[0][0].cveALinea"
            native-value="cveALinea"
          >
          </b-input>
        </b-table-column>

        <b-table-column label="PNR" sortable v-slot="props">
          <b-input
            v-model="props.row[0][0].pnr"
            ref="fieldpnr"
            placeholder="pnr"
            @input="TextoGroup(props.row[0], 3, props.row[0][0].pnr)"
            :value="props.row[0][0].pnr"
            native-value="pnr"
          >
          </b-input>
        </b-table-column>

        <b-table-column label="RUTA" sortable v-slot="props">
          <b-input
            v-model="props.row[0][0].ruta"
            ref="fieldpnr"
            placeholder="ruta"
            @input="TextoGroup(props.row[0], 5, props.row[0][0].ruta)"
            :value="props.row[0][0].ruta"
            native-value="ruta"
          >
          </b-input>
        </b-table-column>
        <b-table-column label="FORMA PAGO" sortable v-slot="props">
          <b-input
            v-model="props.row[0][0].forma_pago"
            ref="fieldforma_pago"
            placeholder="forma_pago"
            @input="TextoGroup(props.row[0], 6, props.row[0][0].forma_pago)"
            :value="props.row[0][0].forma_pago"
            native-value="forma_pago"
          >
          </b-input>
        </b-table-column>

        <b-table-column label="Tarifa Mx" sortable v-slot="props">
          <b-input
            v-model="props.row[0][0].tarifaMx"
            ref="fieldtarifaMx"
            placeholder="tarifaMx"
            @input="TextoGroup(props.row[0], 8, props.row[0][0].tarifaMx)"
            :value="props.row[0][0].tarifaMx"
            native-value="tarifaMx"
          >
          </b-input>
        </b-table-column>

        <b-table-column label="XT" sortable v-slot="props">
          <b-input
            v-model="props.row[0][0].xt"
            ref="fieldxt"
            placeholder="xt"
            @input="TextoGroup(props.row[0], 9, props.row[0][0].xt)"
            :value="props.row[0][0].xt"
            native-value="xt"
          >
          </b-input>
        </b-table-column>
        <b-table-column label="FEE GRP 1USD" sortable v-slot="props">
          <b-input
            v-model="props.row[0][0].fee_grp"
            ref="fielfee_grp"
            placeholder="fee_grp"
            @input="TextoGroup(props.row[0], 12, props.row[0][0].fee_grp)"
            :value="props.row[0][0].fee_grp"
            native-value="fee_grp"
          >
          </b-input>
        </b-table-column>
        <template #detail="props">
          <td></td>

          <b-table
            style="font-size: 10px"
            :data="props.row[0]"
            paginated
            :per-page="10"
            bordered
            striped
            narrowed
            hoverable
            focusable
            mobile-cards
          >
            <template>
              <b-table-column
                label="APELLIDO/NOMBRE"
                field="PasajeroP1"
                searchable
                v-slot="props"
              >
                {{ props.row.PasajeroP1 }}/{{ props.row.PasajeroP2 }}
              </b-table-column>
              <b-table-column searchable label="TKT" v-slot="props">
                <p
                  v-if="
                    props.row.VigenciaP == null || props.row.NumeroP == null
                  "
                >
                  SR DOCS {{ props.row.Linea }} HK1-----{{
                    props.row.FechaN
                  }}-{{ props.row.Genero }}—{{ props.row.PasajeroP1 }}-{{
                    props.row.PasajeroP2
                  }}/P{{ props.row.PasajeroN }}
                </p>

                <p
                  v-if="
                    props.row.VigenciaP != null && props.row.NumeroP != null
                  "
                >
                  SR DOCS {{ props.row.Linea }} HK1-P-{{ props.row.EmisorP }}-{{
                    props.row.NumeroP
                  }}-{{ props.row.Nacionalidad }}-{{ props.row.FechaN }}-{{
                    props.row.Genero
                  }}-{{ props.row.VigenciaP }}-{{ props.row.PasajeroP1 }}-{{
                    props.row.PasajeroP2
                  }}/P{{ props.row.PasajeroN }}
                </p>
              </b-table-column>

              <b-table-column
                label="No. Boleto"
                sortable
                searchable
                v-slot="props"
              >
                <b-input
                  v-model="props.row.boleto"
                  ref="fieldboleto"
                  placeholder="boleto"
                  @input="Textoeditable(props.row.id, 7, props.row.boleto)"
                  :value="props.row.boleto"
                  native-value="boleto"
                >
                </b-input>
              </b-table-column>

              <b-table-column
                searchable
                label="No. Pasaporte"
                sortable
                v-slot="props"
              >
                <b-input
                  v-model="props.row.NumeroP"
                  ref="fieldNumeroP"
                  placeholder="NumeroP"
                  @input="Textoeditable(props.row.id, 10, props.row.NumeroP)"
                  :value="props.row.NumeroP"
                  native-value="NumeroP"
                >
                </b-input>
              </b-table-column>

              <b-table-column
                label="Vigencia Pasaporte"
                sortable
                v-slot="props"
              >
                <b-input
                  v-model="props.row.VigenciaP"
                  ref="fieldVigenciaP"
                  placeholder="VigenciaP"
                  @input="Textoeditable(props.row.id, 11, props.row.VigenciaP)"
                  :value="props.row.VigenciaP"
                  native-value="VigenciaP"
                >
                </b-input>
              </b-table-column>
            </template>
          </b-table>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import MainServices from "@/services/MainServices";
import { XlsxRead, XlsxSheets, XlsxJson } from "vue-xlsx/dist/vue-xlsx.es";
import Constantes from "@/Constantes";
export default {
  components: {
    XlsxRead,
    XlsxSheets,
    XlsxJson,
  },

  data() {
    return {
      fchin: null,
      Groups: [],
      isLoading: false,
      file: null,
      selectedSheet: null,
      contador: 0,
      sheets: [],
      collection: [],
      NewTable: false,
      group: 0,
      Fecha: new Date(),
      groupArray: [],
    };
  },
  mounted() {
    this.ObtenerGroups();
  },
  methods: {
    toggle(row) {
      this.$refs.table.toggleDetails(row);
    },

    onChange() {
      console.log(this.file);
    },
    TextoGroup(dato1, dato2, dato3) {
      for (const element of dato1) {
        const id = element.id;
        this.Textoeditable(id, dato2, dato3);
      }
      this.ObtenerGroups();
    },

    CapturarFecha(fecha, id, opcion) {
      let NameMes = [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ];
      let anioE = fecha.getFullYear();
      let mesE = fecha.getMonth();
      let diaE = fecha.getDate();
      let fechaE = diaE + "-" + NameMes[mesE] + "-" + anioE;

      for (const element of id) {
        const idElement = element.id;
        this.Textoeditable(idElement, opcion, fechaE);
      }

      this.fchin = null;
      this.ObtenerGroups();
    },

    clearDate() {
      this.fchin = null;
    },
    async Textoeditable(id, opcion, texto) {
      const resp = await MainServices.cambiarEstado(id, opcion, texto);
      console.log(resp);
    },

    async Resolver(Data) {
      let anio = this.Fecha.getFullYear();
      let mes = this.Fecha.getMonth() + 1;
      let dia = this.Fecha.getDay();

      let contador = 0;
      let group = 0;

      for (const item of Data) {
        const Doc = item.DOCS;
        const Pasajero = item.Pasajero;
        const Linea = item.Linea;
        if (Doc == null || Pasajero == null) {
          contador = 0;
          group = group + 1;
        } else {
          contador = contador + 1;
          const pruebas = Doc.split("/");
          const resultado = pruebas.filter((prueba) => prueba != "");
          this.groupArray.push({
            PasajeroP1: resultado[6],
            PasajeroP2: resultado[7],
            EmisorP: resultado[2],
            Nacionalidad: resultado[3],
            FechaN: resultado[4],
            Genero: resultado[5],
            PasajeroN: contador,
            Linea: Linea,
            IdGroups: anio + "" + mes + "" + dia + "" + group,
          });
        }
      }

      this.isLoading = true;
      const resp = await MainServices.GuardarGrupos(this.groupArray);
      this.isLoading = false;
      if (resp) {
        this.NewTable = true;
        this.ObtenerGroups();
      }
    },
    async CrearReporteG() {
      try {
        const respuesta = await MainServices.ReporteGrupo(this.DataGroup);
        if (respuesta) {
          this.message("Archivo creado correctamente", "is-success");

          let date = new Date();
          let output =
            "ReporGroup " +
            String(date.getDate()).padStart(2, "0") +
            "/" +
            String(date.getMonth() + 1).padStart(2, "0") +
            "/" +
            date.getFullYear();
          const url =
            Constantes.URL_SERVIDOR + "/descargar_Reporte.php?date=" + output;
          window.open(url);
        } else {
          this.message("Error al crear archivo", "is-danger");
        }
      } catch (e) {
        console.log(e);
        this.message("Error al crear archivo: " + e, "is-danger");
      }
    },
    async ObtenerGroups() {
      this.isLoading = true;
      this.DataGroup = await MainServices.ObtenerDataGroup();
      this.Groups = await MainServices.ObtenerGroups();
      this.isLoading = false;
    },
    message(mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo,
      });
    },
  },
};
</script>


<template>
  <div class="box">
    Resultados: {{ CodeBag.length }}
    <div class="box">
      <b-field>
        <b-numberinput
          placeholder="Introduce la numeración"
          v-model="numeracion"
          required
          ref="fieldnumeracion"
          :controls="false"
        ></b-numberinput>
        <b-input
          placeholder="Introduce el concepto"
          v-model="nomBag"
          required
          ref="fieldnomBag"
          :controls="false"
        ></b-input>
        <b-tooltip label="Agregar">
          <b-button @click="Save()" type="is-info" icon-right="plus" />
        </b-tooltip>
      </b-field>
    </div>
    <b-table
      style="font-size: 12px"
      :data="CodeBag"
      paginated
      :per-page="15"
      bordered
      striped
      narrowed
      hoverable
      focusable
      mobile-cards
    >
      <template>
        <b-table-column
          field="codigo"
          label="Numeración"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.codigo }}
        </b-table-column>
        <b-table-column
          field="ruta"
          label="Concepto"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.ruta }}
        </b-table-column>

        <b-table-column
          field="eliminar"
          label="Eliminar"
          v-slot="props"
          centerd
        >
          <a @click="eliminar(props.row)">
            <b-icon icon="trash" type="is-danger" size="is-small"></b-icon>
          </a>
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
import MainServices from "@/services/MainServices";
export default {
  data() {
    return {
      CodeBag: [],
      nameAgencia: "",
      codigo: "",
      nomBag: null,
      numeracion: null,
    };
  },

  mounted() {
    this.obtenerCodigosBag();
  },
  methods: {
    async eliminar(datos) {
      const respuesta = await MainServices.EliminarCodeBag(datos);
      if (respuesta) {
        this.message("Se elimino correctamente ", "is-success");
        this.obtenerCodigosBag();
      } else {
        this.message("Error al eliminar", "is-danger");
      }
    },
    async obtenerCodigosBag() {
      this.isLoading = true;
      this.CodeBag = await MainServices.obtenerCodigosBag();
      this.isLoading = false;
    },

    async Save() {
      const fields = ["fieldnomBag", "fieldnumeracion"];

      for (const field of fields) {
        if (!this.$refs[field].checkHtml5Validity()) {
          return;
        }
      }
      const respuesta = await MainServices.NuevoCodeBag(
        this.nomBag,
        this.numeracion
      );
      if (respuesta) {
        this.message("Se ha agregado correctamente ", "is-success");
        this.nomBag = null;
        this.numeracion = null;
        this.obtenerCodigosBag();
      } else {
        this.message("Error al actualizar ", "is-danger");
      }
    },
    message(message, tipo) {
      this.$buefy.toast.open({
        message: message,
        type: tipo,
      });
    },
  },
};
</script>

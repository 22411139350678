<template>
  <div class="box">
    <div class="box">
      <b-field label="Buscar Boleto" label-position="on-border">
        <b-numberinput
          :controls="false"
          v-model="numero"
          @input="Buscar"
        ></b-numberinput
      ></b-field>

      <div class="columns">
        <div class="column" v-if="datos.num_bol">
          <strong>Clave Usuario:</strong> {{ datos.clave_user }} <br />
          <strong>Nombre Usuario:</strong> {{ datos.nom_user }}<br />
          <strong>Fecha de Registro:</strong> {{ datos.fecha_registro }}<br />
        </div>
        <div class="column" v-if="datos.num_bol">
          <b-field label="TERMINAL" label-position="on-border">
            <b-input
              v-model="datos.terminal"
              ref="fieldterminal"
              placeholder="terminal"
              @input="EditarBoleto(1, datos.terminal)"
              :value="datos.terminal"
              native-value="terminal"
            >
            </b-input>
          </b-field>

          <b-field label="No. Cargo" label-position="on-border">
            <b-input
              v-model="datos.numCargo"
              ref="fieldnumCargo"
              placeholder="Numero de Cargo"
              @input="EditarBoleto(2, datos.numCargo)"
              :value="datos.numCargo"
              native-value="numCargo"
            >
            </b-input>
          </b-field>

          <b-field label="Monto" label-position="on-border">
            <b-input
              v-model="datos.monto"
              ref="fieldmonto"
              placeholder="Monto"
              @input="EditarBoleto(3, datos.monto)"
              :value="datos.monto"
              native-value="monto"
            >
            </b-input>
          </b-field>

          <b-field label="No. Targeta" label-position="on-border">
            <b-input
              v-model="datos.ter_Tarjet"
              ref="fieldter_Tarjet"
              placeholder="ter_Tarjet"
              @input="EditarBoleto(4, datos.ter_Tarjet)"
              :value="datos.ter_Tarjet"
              native-value="ter_Tarjet"
              minlength="4"
              maxlength="4"
            >
            </b-input>
          </b-field>

          <b-field label="Folio" label-position="on-border">
            <b-input
              v-model="datos.factura_bol"
              ref="fieldfactura"
              placeholder="Factura"
              @input="EditarBoleto(5, datos.factura_bol)"
              :value="datos.factura_bol"
              native-value="factura"
            >
            </b-input>
          </b-field>
        </div>
      </div>
    </div>

    <div class="box">
      <section>
        <b-field
          label="Seleccione la fecha para descargar el reporte"
          label-position="on-border"
        >
          <b-datepicker
            v-model="selected"
            :locale="locale"
            placeholder="Selecciona la fecha"
            icon="calendar"
            :icon-right="selected ? 'close-circle' : ''"
            icon-right-clickable
            @icon-right-click="clearDate"
            trap-focus
            @input="MandarFecha(selected)"
          >
          </b-datepicker
          >&nbsp;&nbsp;&nbsp;
          <b-button
            v-if="reservas.length > 0"
            outlined
            type="is-success"
            icon-left="download"
            @click="CrearReporte()"
            >Descargar</b-button
          >&nbsp;&nbsp;&nbsp;
          <h1 v-if="reservas.length > 0">
            Resultados encontrados: {{ reservas.length }}
          </h1>
        </b-field>
      </section>

      <div class="columns" v-if="reservas.length > 0">
        <div class="column">
          <b-table
            style="font-size: 12px"
            :data="isEmpty ? [] : reservas"
            :paginated="true"
            :per-page="10"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :hoverable="true"
            :focusable="true"
            :mobile-cards="true"
            :pagination-rounded="true"
          >
            <template>
              <b-table-column
                field="nom_user"
                label="Agente"
                sortable
                v-slot="props"
              >
                {{ props.row.nom_user }}
              </b-table-column>
              <b-table-column
                field="clave_cliente"
                label="Agencia"
                sortable
                v-slot="props"
              >
                {{ props.row.clave_cliente }}
              </b-table-column>
              <b-table-column field="pnr" label="PNR" sortable v-slot="props">
                {{ props.row.pnr }}
              </b-table-column>
              <b-table-column
                field="pasajero"
                label="Pasajero"
                sortable
                v-slot="props"
              >
                {{ props.row.pasajero }}
              </b-table-column>
              <b-table-column
                field="boleto"
                label="Boletos"
                sortable
                v-slot="props"
              >
                {{ props.row.boleto }}
              </b-table-column>
              <b-table-column
                field="fecha_registro"
                label="Fecha"
                sortable
                v-slot="props"
              >
                {{ props.row.fecha_registro }}
              </b-table-column>

              <b-table-column
                field="terminal"
                label="Terminal"
                sortable
                v-slot="props"
              >
                {{ props.row.terminal }}
              </b-table-column>
              <b-table-column
                field="numCargo"
                label="No. de cargo"
                sortable
                v-slot="props"
              >
                {{ props.row.numCargo }}
              </b-table-column>
              <b-table-column
                field="monto"
                label="Monto"
                sortable
                v-slot="props"
              >
                {{ props.row.monto }}
              </b-table-column>

              <b-table-column
                field="ter_Tarjet"
                label="Terminacion tarjeta"
                sortable
                v-slot="props"
              >
                {{ props.row.ter_Tarjet }}
              </b-table-column>
              <b-table-column
                field="factura"
                label="Factura"
                sortable
                v-slot="props"
              >
                {{ props.row.factura_bol }}
              </b-table-column>
            </template>
            <template #empty>
              <div class="has-text-centered">Sin resultados</div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainServices from "@/services/MainServices";
import Constantes from "@/Constantes";
export default {
  data() {
    return {
      selected: undefined,
      locale: undefined,
      terminal: null,
      numero: null,
      datos: [],
      reservas: [],
      isEmpty: false,
    };
  },

  mounted() {},

  methods: {
    clearDate() {
      this.selected = null;
      this.reservas = null;
    },
    async Buscar() {
      this.datos = await MainServices.obtenerBoleto(this.numero);
      console.log(this.datos);
    },
    async EditarBoleto(opcion, texto) {
      console.log(this.numero, opcion, texto);
      const resp = await MainServices.cambiarBoleto(this.numero, opcion, texto);
      console.log(resp);
    },
    async MandarFecha(FechaSolicitada) {
      try {
        this.reservas = await MainServices.MandarFecha(FechaSolicitada);
        if (this.reservas) {
          this.message("Fecha Actualizada", "is-success");
        } else {
          this.message("Error al ingresar la fecha", "is-danger");
        }
      } catch (e) {
        this.message("Error al ingresar la fecha: " + e, "is-danger");
      }
    },

    async CrearReporte() {
      try {
        const respuesta = await MainServices.ReportePendientes(this.reservas);

        if (respuesta) {
          this.message("Archivo creado correctamente", "is-success");

          let date = new Date();
          let output =
            "Revisados " +
            String(date.getDate()).padStart(2, "0") +
            "/" +
            String(date.getMonth() + 1).padStart(2, "0") +
            "/" +
            date.getFullYear();
          const url =
            Constantes.URL_SERVIDOR + "/descargar_Reporte.php?date=" + output;
          window.open(url);
        } else {
          this.message("Error al crear archivo", "is-danger");
        }
      } catch (e) {
        console.log(e);
        this.message("Error al crear archivo: " + e, "is-danger");
      }
    },
    message(mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo,
      });
    },
  },
};
</script>


<template>
  <div class="box">
    <b-loading :is-full-page="isFullPage" v-model="isLoading"> </b-loading>
    <div class="box" style="text-align: center">
      <br />
      &nbsp; &nbsp; &nbsp;
      <br />
      <div class="upload">
        <b-field class="file is-info" :class="{ 'has-name': !!file }">
          <b-upload
            v-model="file"
            class="file-label"
            rounded
            @input="onChange"
            accept=".xls,.xlsx"
          >
            <span class="file-cta">
              <b-icon class="file-icon" icon="upload"></b-icon>
              <span class="file-label">Cargar Archivo</span>
            </span>
            <span class="file-name" v-if="file">
              {{ file.name }}
            </span>
          </b-upload>
        </b-field>
      </div>
      <br />
      &nbsp; &nbsp; &nbsp;
      <br />
      <xlsx-read :file="file">
        <div class="columns">
          <div class="column">
            <xlsx-sheets v-if="file">
              <template #default="{ sheets }">
                <div v-if="sheets.length >= 1">
                  <br />

                  <h1>Elije la hoja del libro</h1>
                  <b-select
                    v-model="selectedSheet"
                    placeholder="Selecciona la opción"
                    rounded
                  >
                    <option v-for="sheet in sheets" :key="sheet" :value="sheet">
                      {{ sheet }}
                    </option>
                  </b-select>
                </div>
              </template>
            </xlsx-sheets>
          </div>

          <div class="column">
            <xlsx-json :sheet="selectedSheet" v-if="selectedSheet">
              <template #default="{ collection }">
                Resultados: <strong>{{ collection.length }}</strong>
                <b-table
                  style="font-size: 12px"
                  :data="collection"
                  paginated
                  :per-page="15"
                  bordered
                  striped
                  narrowed
                  hoverable
                  focusable
                  mobile-cards
                >
                  <template>
                    <b-table-column label="Boleto" sortable v-slot="props">
                      {{ obtenerBoleto(props.row) }}
                    </b-table-column>
                    <b-table-column
                      label="Cve. Usuario"
                      sortable
                      v-slot="props"
                    >
                      {{ obtenerCveUser(props.row) }}
                    </b-table-column>
                  </template>
                </b-table>

                <section v-if="collection && file">
                  <br />
                  <b-button
                    label="Consultar"
                    @click="Resolver(collection)"
                    type="is-info"
                    rounded
                  />
                </section>
              </template>
            </xlsx-json>
          </div>
        </div>
      </xlsx-read>

      <div class="box" v-if="this.valorBar > 0">
        <b-progress
          :rounded="false"
          :value="valorBar"
          type="is-success"
          show-value
          format="percent"
        ></b-progress>
      </div>
    </div>
  </div>
</template>

<script>
import MainServices from "@/services/MainServices";
import { XlsxRead, XlsxSheets, XlsxJson } from "vue-xlsx/dist/vue-xlsx.es";
export default {
  components: {
    XlsxRead,
    XlsxSheets,
    XlsxJson,
  },

  data() {
    return {
      isLoading: false,
      file: null,
      selectedSheet: null,
      isFullPage: true,
      contadorSave: null,
      progresoBar: 0,
      isImageModalActive: false,
      isModalCard: false,
      DataAgencias: [],
      total: 0,
      longSave: null,
      save: 0,
      contador: 0,
      ValorTime: 5000,
      sheets: [],
      collection: [],
      newArray: [],
      showDetailIcon: true,
      DataTable: [],
      labelPosition: "bottom",
      showSocial: false,
      Matches: [],
      ExceptionsClean: null,
      Traspaso: null,
      TraspasoMatch: null,
      cleanBag: null,
      LongCxS: 0,
      MatchesCount: 0,
      mitadFinish: null,
      NameUser: null,
      globName: null,
      noExisten: 0,
      valorBar: 0,
    };
  },
  mounted() {},
  methods: {
    toggle(row) {
      this.$refs.table.toggleDetails(row);
    },

    onChange() {
      console.log(this.file);
    },
    obtenerBoleto(row) {
      switch (this.selectedSheet) {
        case "AMADEUS": {
          this.globName = "DOC NUMBER";

          break;
        }

        case "SABRE": {
          this.globName = "NUMERO DE";

          break;
        }

        case "WSPAN": {
          this.globName = "columna3";

          break;
        }
        case "GALILEO": {
          this.globName = "ERO DE TKT";

          break;
        }
      }

      return row[this.globName];
    },

    obtenerCveUser(row) {
      switch (this.selectedSheet) {
        case "AMADEUS": {
          this.NameUser = "AS";
          break;
        }

        case "SABRE": {
          this.NameUser = "FIRMA DEL AGENTE";
          break;
        }

        case "WSPAN": {
          this.NameUser = "columna8";

          break;
        }
        case "GALILEO": {
          this.NameUser = "AGENTE";

          break;
        }
      }
      return row[this.NameUser];
    },
    async Resolver(Data) {
      for (const item of Data) {
        let boleto = this.obtenerBoleto(item);
        let cveuser = this.obtenerCveUser(item);

        if (!boleto || !cveuser) {
          this.noExisten = this.noExisten + 1;
        } else {
          this.newArray.push({
            boleto: boleto,
            cveuser: cveuser,
          });
        }
      }
      let arregloDeArreglos = [];
      const LONGITUD_PEDAZOS = this.newArray.length / 4;

      for (let i = 0; i < this.newArray.length; i += LONGITUD_PEDAZOS) {
        let pedazo = this.newArray.slice(i, i + LONGITUD_PEDAZOS);
        arregloDeArreglos.push(pedazo);
      }

      let i = 0;
      this.isLoading = true;
      this.valorBar = 5;
      do {
        try {
          const resp = await MainServices.GuardarBoletos(arregloDeArreglos[i]);
          console.log(resp);
        } catch (e) {
          console.log(e);
        }
        this.valorBar = this.valorBar + 5;
        i++;
      } while (i < 4);
      this.isLoading = false;
      this.MatchAgent();
    },
    async MatchAgent() {
      let i = 0;
      this.valorBar = 25;
      try {
        do {
          this.isLoading = true;
          const resp = await MainServices.BuscarAgente(i);
          console.log(resp);
          this.isLoading = false;
          this.valorBar = this.valorBar + 25;
          i++;
        } while (i < 3);
      } catch (error) {
        console.error("Error al buscar el agente:", error);
      } finally {
        this.valorBar = 0;
        this.isLoading = false;
      }
    },
  },
};
</script>


<template>
  <div class="box">
    <b-loading is-full-page v-model="isLoading"> </b-loading>

    <div class="columns">
      <div class="column">
        <b-field label="Selecciona la fecha" label-position="on-border">
          <b-datepicker
            placeholder="Click para seleccionar..."
            v-model="dates"
            range
            @input="BuscarPorFecha"
          >
          </b-datepicker>
        </b-field>
      </div>

      <div class="column">Resultados: {{ Matches.length }}</div>
      <div class="column">
        <b-field>
          <b-button
            icon-left="download"
            outlined
            type="is-success"
            @click="CrearReporte()"
            >Descargar</b-button
          >
        </b-field>
      </div>
    </div>

    <b-table
      style="font-size: 12px"
      :data="Matches"
      paginated
      :per-page="10"
      bordered
      striped
      narrowed
      hoverable
      focusable
      mobile-cards
    >
      <template>
        <b-table-column
          field="clave_cliente"
          label="ID IRIS"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.clave_cliente }}
        </b-table-column>
        <b-table-column
          field="nombre_cliente"
          label="USER NAME"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.nombre_cliente }}
        </b-table-column>
        <b-table-column
          field="serie"
          label="serie"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.serie }}
        </b-table-column>
        <b-table-column
          field="factura"
          label="factura"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.factura }}
        </b-table-column>
        <b-table-column
          field="fecha"
          label="fecha"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.fecha }}
        </b-table-column>
        <b-table-column
          field="pasajero"
          label="pasajero"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.pasajero }}
        </b-table-column>
        <b-table-column
          field="ruta"
          label="ruta"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.ruta }}
        </b-table-column>
        <b-table-column
          field="linea_aerea"
          label="linea_aerea"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.linea_aerea }}
        </b-table-column>
        <b-table-column
          field="boleto"
          label="boleto"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.boleto }}
        </b-table-column>
        <b-table-column
          field="total"
          label="total"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.total }}
        </b-table-column>
        <b-table-column
          field="forma_pago"
          label="forma_pago"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.forma_pago }}
        </b-table-column>
        <b-table-column
          field="pnr"
          label="pnr"
          sortable
          searchable
          v-slot="props"
        >
          {{ props.row.pnr }}
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
import MainServices from "@/services/MainServices";
import Constantes from "@/Constantes";

export default {
  data() {
    return {
      Matches: [],
      dates: [],
      isLoading: false,
    };
  },

  mounted() {
    this.ObtenerMatches();
  },
  methods: {
    async BuscarPorFecha() {
      let fecha1 = "";
      let fecha2 = "";
      for (let index = 0; index < this.dates.length; index++) {
        let anio = this.dates[index].getFullYear();
        let mes = (this.dates[index].getMonth() + 1)
          .toString()
          .padStart(2, "0");
        let dia = this.dates[index].getDate().toString().padStart(2, "0");
        if (index === 0) {
          fecha1 = anio + "-" + mes + "-" + dia;
        } else {
          fecha2 = anio + "-" + mes + "-" + dia;
        }
      }
      this.isLoading = true;
      const respuesta = await MainServices.BuscarFecha(fecha1, fecha2);
      this.Matches = respuesta;
      this.isLoading = false;
    },
    async ObtenerMatches() {
      this.isLoading = true;
      this.Matches = await MainServices.ObtenerMatches();
      this.isLoading = false;
    },

    async CrearReporte() {
      try {
        const respuesta = await MainServices.ReporteConciliados(this.Matches);
        console.log(respuesta);
        if (respuesta) {
          this.message("Archivo creado correctamente", "is-success");

          let date = new Date();
          let output =
            "Conciliados " +
            String(date.getDate()).padStart(2, "0") +
            "/" +
            String(date.getMonth() + 1).padStart(2, "0") +
            "/" +
            date.getFullYear();
          const url =
            Constantes.URL_SERVIDOR + "/descargar_Reporte.php?date=" + output;
          window.open(url);
        } else {
          this.message("Error al crear archivo", "is-danger");
        }
      } catch (e) {
        console.log(e);
        this.message("Error al crear archivo: " + e, "is-danger");
      }
    },
    message(mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo,
      });
    },
  },
};
</script>


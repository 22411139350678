<template>
  <div>
    <div class="tituloInicio">
      <h1 class="letterInicio"><strong>VALIDAR CXS</strong></h1>
    </div>
    <b-tabs type="is-toggle-rounded" expanded>
      <b-tab-item label="Tabla Pendientes" icon="thumbs-down">
        <TablaPendientes />
      </b-tab-item>
      <b-tab-item label="Menu Excepciones" icon="thumbs-down">
        <b-tabs type="is-boxed" expanded>
          <b-tab-item label="EMDs" icon="briefcase">
            <TablaBag />
          </b-tab-item>
          <b-tab-item label="Agencias" icon="user-circle">
            <TablaAgencias />
          </b-tab-item>
        </b-tabs>
      </b-tab-item>
      <b-tab-item label="Agentes" icon="user-secret">
        <b-tabs type="is-boxed" expanded>
          <b-tab-item label="Cargar BoletosGDS" icon="ticket">
            <ConciliarAgentes />
          </b-tab-item>
          <b-tab-item label="Alta de Agentes" icon="user-secret">
            <TablaAgente /> </b-tab-item
        ></b-tabs>
      </b-tab-item>
      <b-tab-item label="Cargar Archivo" icon="upload">
        <CargaArchivo
      /></b-tab-item>

      <b-tab-item label="Revisados" icon="thumbs-up">
        <b-tabs type="is-boxed" expanded>
          <b-tab-item label="Buscar Boleto" icon="thumbs-up">
            <DesglosePago />
          </b-tab-item>
          <b-tab-item label="Tabla Conciliados" icon="thumbs-up">
            <TablaConciliados />
          </b-tab-item>
        </b-tabs>
      </b-tab-item>

      <b-tab-item label="GruposGDS" icon="users">
        <GruposGDS />
      </b-tab-item>
    </b-tabs>
    <div class="body">
      <router-view />
    </div>
  </div>
</template>

<script>
import TablaAgencias from "@/components/TablaAgencias.vue";
import CargaArchivo from "@/components/CargaArchivo";
import TablaPendientes from "@/components/TablaPendientes.vue";
import TablaConciliados from "@/components/TablaConciliados.vue";
import TablaBag from "@/components/TablaBag.vue";
import TablaAgente from "@/components/TablaAgentes.vue";
import ConciliarAgentes from "@/components/ConciliarAgentes.vue";
import GruposGDS from "@/components/GruposGDS.vue";
import DesglosePago from "@/components/DesglosePago.vue";

export default {
  name: "VerReservaciones",
  components: {
    DesglosePago,
    CargaArchivo,
    TablaAgencias,
    TablaPendientes,
    TablaConciliados,
    TablaBag,
    TablaAgente,
    ConciliarAgentes,
    GruposGDS,
  },
  data() {
    return {};
  },

  mounted() {},
  methods: {
    navegarHacia(nombreRuta) {
      this.$router.push({ name: nombreRuta });
      this.menuEstaActivo = false;
    },
    message(mensaje, tipo) {
      this.$buefy.toast.open({
        message: mensaje,
        type: tipo,
      });
    },
  },
};
</script>
<style>
.letterInicio {
  font-size: xx-large;
  color: rgb(184, 187, 190);
}
.tituloInicio {
  background: rgb(246, 111, 0);
  background: radial-gradient(
    circle,
    rgba(246, 111, 0, 1) 0%,
    rgba(0, 18, 130, 1) 100%
  );
}
</style>
<template>
  <div class="box">
    <b-taglist attached>
      <b-tag type="is-dark">Total</b-tag>
      <b-tag type="is-info">{{ total }}</b-tag> &nbsp; &nbsp; &nbsp;

      <b-tag type="is-dark">Restantes</b-tag>
      <b-tag type="is-success">{{ DataTable.length }}</b-tag>
    </b-taglist>
    <b-steps :animated="true" :rounded="true" :label-position="labelPosition" position="is-right">
      <template #navigation="{ previous, next }">

        <div class="rows">
          <div class="row" style="text-align: center;">
            <b-step-item step="1" label="Cargar Archivo" :clickable="false" type="is-info">
                              <br>
                &nbsp; &nbsp; &nbsp;
                <br>
                <div class="upload">
                  <b-field class="file is-info" :class="{ 'has-name': !!file }">
                    <b-upload v-model="file" class="file-label" rounded @input="onChange" accept=".xls,.xlsx">
                      <span class="file-cta">
                        <b-icon class="file-icon" icon="upload"></b-icon>
                        <span class="file-label">Cargar Archivo</span>
                      </span>
                      <span class="file-name" v-if="file">
                        {{ file.name }}
                      </span>
                    </b-upload>
                  </b-field>
                </div>
                <br>
                &nbsp; &nbsp; &nbsp;
                <br>
                <xlsx-read :file="file">
                  <div class="columns">
                    <div class="column">
                      <xlsx-sheets v-if="file">
                        <template #default="{ sheets }">
                          <div v-if="sheets.length >= 1">
                            <br />

                            <h1>Elije la hoja del libro</h1>
                            <b-select v-model="selectedSheet" placeholder="Selecciona la opción" rounded
                              @input="openLoading">
                              <option v-for="sheet in sheets" :key="sheet" :value="sheet">
                                {{ sheet }}
                              </option>
                            </b-select>
                          </div>
                        </template>
                      </xlsx-sheets>
                    </div>

                    <div class="column" v-if="!isLoading">
                      <xlsx-json :sheet="selectedSheet">
                        <template #default="{ collection }">
                          <section v-if="collection && file">
                            <br />
                            <b-button label="Continuar" @click="Resolver(collection)" @click.prevent="next.action"
                              type="is-info" rounded />

                          </section>
                        </template>
                      </xlsx-json>
                    </div>
                  </div>
                </xlsx-read>
              
            </b-step-item>
            <b-step-item step="2" label="Validación" :clickable="false" type="is-danger">
              
                <h1 class="title is-4 has-text-centered">
                  Se omitieron los siguientes datos:
                </h1>
                <p>
                <ul>-las series diferentes a "DSE3, FS3 y FS4".</ul>
                <ul>-lineas mayores de 3 digitos.</ul>
                <ul>-la linea aerea Y4.</ul>
                </p>
                <br>
                &nbsp; &nbsp; &nbsp;
                <br>
                <b-button label="Continuar" @click="EliminarDeBD()" @click.prevent="next.action" type="is-danger"
                  rounded />
                <br>
                &nbsp; &nbsp; &nbsp;
                <br>
              
            </b-step-item>

             
            <b-step-item :step="showSocial ? '4' : '3'" label="Finalizar"  :clickable="false" type="is-success" disabled>
              <h1 class="title is-4 has-text-centered">
                Se conciliarón los boletos con los siguientes criterios:
              </h1>
              <p>
              <ul>1.-Se capturarón en una tabla los registros que tienen CxS en el campo ''Linea aérea''.</ul>
              <ul>2.-Se eliminaron de la tabla de pendientes los boletos con CxS encontrados.</ul>
              <ul>3.-Se eliminaron los boletos que pertenecen a las agencias con excepciones.</ul>
              <ul>4.-Se eliminaron los boletos de equipaje.</ul>
            </p>
              <br>
              &nbsp; &nbsp; &nbsp;
              <br>
     
              <b-button  label="Finalizar" @click="Finalizar()"   type="is-success"
                rounded />
              <br>
              &nbsp; &nbsp; &nbsp;
              <br>
            </b-step-item>
            
          </div>
          <div class="row">
            <b-button type="is-danger" icon-left="chevron-left" size="is-small" :disabled="previous.disabled"
              @click.prevent="previous.action" />
            <b-button type="is-success" size="is-small" icon-right="chevron-right" :disabled="next.disabled"
              @click.prevent="next.action" />
          </div>
        </div>
      </template>
    </b-steps>

    <div class="box" v-if="isModalCard">
      <b-loading :is-full-page="isFullPage" v-model="isModalCard"> </b-loading>
      <b-modal v-model="isModalCard" :can-cancel="false">



        <figure class="figure">
          <img alt="cargando" src="@/assets/4AIE.gif" class="figure-img img-fluid" />
        </figure>


        
        <b-progress :max="LongCxS" :value="MatchesCount" type="is-danger"
                show-value>
              </b-progress>

                   
              <b-progress type="is-success" show-value>
                <template #bar>
                  <b-progress-bar :value="35" type="is-info" v-if="ExceptionsClean">Eliminar
                    Excepciones</b-progress-bar>
                  <b-progress-bar :value="35" type="is-warning" v-if="cleanBag">Se eliminaron EMDs</b-progress-bar>
                  <b-progress-bar :value="30" type="is-success" v-if="pnrWhite">Se eliminaron PNRs vacios</b-progress-bar>

                </template>
              </b-progress>
      </b-modal>
    </div>


    <div class="box" v-if="isImageModalActive">
      <b-loading :is-full-page="isFullPage" v-model="isImageModalActive"> </b-loading>
      <b-modal v-model="isImageModalActive" :can-cancel="false">
        <figure class="figure">
          <img alt="cargando" src="@/assets/progreso.gif" class="figure-img img-fluid" />
        </figure>

        <b-progress :max="progresoBar" :value="contadorSave" type="is-success" show-value>
        </b-progress>
      </b-modal>
    </div>
  </div>
</template>

<script>
import MainServices from "@/services/MainServices";
import { XlsxRead, XlsxSheets, XlsxJson } from "vue-xlsx/dist/vue-xlsx.es";
export default {
  name: "InicioComponente",
  components: {
    XlsxRead,
    XlsxSheets,
    XlsxJson,
  },

  data() {
    return {
      contadorSave: null,
      progresoBar: 0,
      isImageModalActive: false,
      isModalCard: false,
      DataAgencias: [],
      total: 0,
      longSave: null,
      save: 0,
      contador: 0,
      ValorTime: 5000,
      isLoading: false,
      isFullPage: true,
      file: null,
      selectedSheet: null,
      sheets: [],
      collection: [],
      newArray: [],
      showDetailIcon: true,
      DataTable: [],
      labelPosition: "bottom",
      showSocial: false,
      Matches: [],
      ExceptionsClean: null,
      Traspaso: null,
      TraspasoMatch: null,
      cleanBag: null,
      LongCxS: 0,
      MatchesCount: 0,
      mitadFinish: null,
      pnrWhite: null,
      newTabla: [],
      contador1: 0,
      contador2: 0,
      arrayDatos: [],
    };
  },
  mounted() {
    this.obtenerNewDatos();
  },
  methods: {
    openLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, this.ValorTime);
    },
    toggle(row) {
      this.$refs.table.toggleDetails(row);
    },

    onChange() {
      this.openLoading();
    },
    Resolver(Data) {
      this.total = Data.length;
      this.arrayDatos = [];

      for (const item of Data) {
        const {
          clave_cliente,
          nombre_cliente,
          serie,
          factura,
          fecha,
          pasajero,
          ruta,
          linea_aerea,
          boleto,
          total,
          forma_pago,
          pnr,
          clases,
        } = item;

        // 1.- Filtrar Serie
        if (["DSE3", "FS3", "FS4"].includes(serie)) {
          // 2.- Quitar lineas mayores de 3 digitos y Y4
          if (
            linea_aerea.length <= 3 &&
            linea_aerea !== "Y4"
          ) {
            this.arrayDatos.push({
              clave_cliente,
              nombre_cliente,
              serie,
              factura,
              fecha,
              pasajero: pasajero || "",
              ruta,
              linea_aerea,
              boleto: boleto || "",
              total,
              forma_pago: forma_pago || "",
              pnr: pnr || "",
              clases: clases || "",
            });
            this.contador++;
          }
        }
      }

      this.guardarArray();
    },
    Finalizar() {
      this.obtenerNewDatos();
      location.reload();
    },
    message(message, tipo) {
      this.$buefy.toast.open({
        message: message,
        type: tipo,
      });
    },
    async guardarArray() {
      let arregloDeArreglos = [];
      const LONGITUD_PEDAZOS = this.arrayDatos.length / 6;

      for (let i = 0; i < this.arrayDatos.length; i += LONGITUD_PEDAZOS) {
        let pedazo = this.arrayDatos.slice(i, i + LONGITUD_PEDAZOS);
        arregloDeArreglos.push(pedazo);
      }

      let i = 0;
      do {
        try {
          this.progresoBar = this.DataTable.length + this.contador;
          this.isLoading = true;
          this.isImageModalActive = true;
          this.obtenerContador();
          const resp = await MainServices.GuardarDatos(arregloDeArreglos[i]);
          console.log(resp);
        } catch (e) {
          console.log(e);
        }

        i++;
      } while (i < 6);

      this.file = null;
      this.isImageModalActive = false;
      this.isLoading = false;
      this.EliminarDeBD();
      this.obtenerNewDatos();
    },

    async obtenerContador() {
      let count = "count(*)";
      do {
        const result = await MainServices.obtenerContador();
        this.contadorSave = result[0][count];
      } while (this.isImageModalActive);
    },
    async EliminarDeBD() {
      this.isModalCard = true;
      this.ExceptionsClean = await MainServices.EliminarDeBD();
      this.cleanBag = await MainServices.EliminarBag();
      this.pnrWhite = await MainServices.EliminarPNR();
      this.message("Se eliminaron excepciones correctamente", "is-success");
      this.isModalCard = false;
    },
    async obtenerNewDatos() {
      this.isLoading = true;
      this.DataTable = await MainServices.obtenerNewDatos();
      this.isLoading = false;
    },
  },
};
</script>



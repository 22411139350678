import Vue from "vue";
import Router from "vue-router"
import Inicio from "@/components/Inicio";
import AdminXLSX from "@/components/AdminXLSX";


Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: "/",
            name: "Inicio",
            component: Inicio,
        },
        {
            path: "/AdminXLSX",
            name: "AdminXLSX",
            component: AdminXLSX,
        },

    ]
});

export default router;

